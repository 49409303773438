$(document).on('turbolinks:load ajax:send ajax:success ajax:error ajax:complete ready', function () {
function handle_bulk_assignment() {
  const parentCheckbox  = $('#bulk-parent');
  const bulkButton      = $('.bulk-assign-btn');
  const childCheckbox   = $('.init-assignment');
  const filterDropdown  = $('.filter-dropdown');

  // Remove previously attached event handlers
  bulkButton.off('click');
  childCheckbox.off('change');
  parentCheckbox.off('change');
  $('#cancel-btn').off('click');
  $('#toggle-confirmation-btn').off('click');
  $('#submit-bulk-assign').off('click');

  // Show Dropdown
  bulkButton.on('click', function () {
    filterDropdown.toggle();
  });

  // Toggle Assign button
  childCheckbox.on('change', function () {
    if ($('.init-assignment:checked').length > 0) {
      bulkButton.removeClass('no-display');
    } else {
      bulkButton.addClass('no-display');
      parentCheckbox.prop('checked', false);
    }
  });

  // Toggle Child Checkboxes
  parentCheckbox.on('change', function () {
    if (this.checked) {
      childCheckbox.prop('checked', true);
      bulkButton.removeClass('no-display');
    } else {
      childCheckbox.prop('checked', false);
      bulkButton.addClass('no-display');
    }
  });

  // Toggle & dropdown
  parentCheckbox.on('change', function () {
    if (this.checked) {
      bulkButton.removeClass('no-display');
    } else {
      bulkButton.addClass('no-display');
    }
  });

  // Hide Dropdown
  $('#cancel-btn').on('click', function () {
    filterDropdown.toggle();
  });

  // Show Confirmation Modal
  $('#toggle-confirmation-btn').on('click', function () {
    const userId = $('#bulk-assign-dropdown').val();

    if (userId === 'unassign') {
      $('#submit-bulk-assign').text('Unassign');
      $('#modal-header').text('Unassign Task');
    } else if (userId !== 'unassign') {
      $('#modal-header').text('Assign Task');
      $('#submit-bulk-assign').text('Assign');
    }
    $('#confirmation-modal').modal('show');
  });

  // Submit AJAX Request
  $('#submit-bulk-assign').on('click', function () {
    const taskIds = [];
    const userId = $('#bulk-assign-dropdown').val();

    $('input[name="task[ids][]"]').each(function () {
      if ($(this).is(':checked')) {
        taskIds.push($(this).val());
      }
    });

    $.ajax({
      url: '/tasks/bulk_assigment',
      method: 'POST',
      data: {task: {ids: taskIds, user_id: userId}},
      success: function (response) {
      },
      error: function (xhr) {
      }
    });
  });
}

function get_rows_task_ids() {
  var task_ids = [];
  $('[class*="specific_task_row_"]').each(function () {
    var className = $(this).attr('class').split(' ').find(c => c.startsWith('specific_task_row_'));
    var numericPart = className.match(/specific_task_row_(\d+)/);
    if (numericPart) {
      task_ids.push(numericPart[1]);
    }
  });
  return task_ids;
}

function set_task_ids_on_current_page() {
  const task_ids_on_current_page = get_rows_task_ids();
  const cond = $('.task_ids_on_current_page').length > 0 && task_ids_on_current_page.length > 0;


  $(".task_ids_on_current_page").val("");
  if (cond) {
    $.each(task_ids_on_current_page, function(index, value) {
      $(".task_ids_on_current_page").val(function(i, val) {
        return val ? val + ',' + value : value;
      });
    });
  }
}

// Expose the functions to the global scope
window.handle_bulk_assignment = handle_bulk_assignment;
window.get_rows_task_ids = get_rows_task_ids;
window.set_task_ids_on_current_page = set_task_ids_on_current_page;

  // Initialize the bulk assignment functionality
  handle_bulk_assignment();
  set_task_ids_on_current_page();
});

