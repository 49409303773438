import $ from 'jquery';

$(document).ready(function () {
    // Triggered when the eligibility is fetched
    $(document).on('ajax:success', 'a[data-target="#eligibilityModal"]', function(event, data) {
        const modal = $('#eligibilityModal');
        const loader = modal.find('#loader');
        const eligibilityResponseContent = modal.find('#eligibilityResponseContent');

        loader.show();
        eligibilityResponseContent.hide().html(data); // Assuming @response contains HTML content

        // When loading is done, hide the loader and show the content
        loader.hide();
        eligibilityResponseContent.fadeIn();

        // Show the modal
        modal.modal('show');
    });
});
