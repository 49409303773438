import $ from 'jquery';

$(document).on('turbolinks:load', function () {
  $('#mb-provider-bill').click(function () {
    $('#mb-through-pharmacy').prop('checked', false);
  });
  $('#mb-through-pharmacy').click(function () {
    $('#mb-provider-bill').prop('checked', false);
  });
});

$(document).ready(function () {
  let hideIframeBtn = $('#hideIframeBtn');
  hideIframeBtn.on('click', function () {
    mahlerIframeContainer.toggleClass('no-display');
  });
});

$(document).on('turbolinks:load', function() {
  $('.request-mahler-link').on('click', function() {
    $(this).text('Requesting...').addClass('disabled');
  });
});
