function validateAttributeFields(){
  const fieldTypeSelect = $('#attribute_field_field_type');
  const valuesInput = $('#attribute_field_values');
  const nameInput = $('#attribute_field_name');
  const selectValuesMessage = $('#select-values-message');
  const dateValuesMessage = $('#date-values-message');
  const checkboxValuesMessage = $('#checkbox-values-message');
  const nameValuesMessage = $('#name-values-message');

  function validateValues() {
    // Check if valuesInput exists and retrieve its value
    const values = valuesInput.length ? valuesInput.val().trim() : '';
    const fieldType = fieldTypeSelect.val();

    // Check if the values are empty or not comma-separated
    if (
        (fieldType === 'select' || fieldType === 'multi_select') &&
      (!values || !/^[^,]+(,[^,]+)*$/.test(values))
    ) {
      selectValuesMessage.removeClass('d-none');
      checkboxValuesMessage.addClass('d-none');
      dateValuesMessage.addClass('d-none');
    } else if (
      fieldType === 'checkbox' &&
      values !== 'true' &&
      values !== 'false'
    ) {
      selectValuesMessage.addClass('d-none');
      dateValuesMessage.addClass('d-none');
      checkboxValuesMessage.removeClass('d-none');
    } else if (
      fieldType === 'date' &&
      !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2[0-9]|3[01])\/\d{4}$/.test(values)
    ) {
      selectValuesMessage.addClass('d-none');
      checkboxValuesMessage.addClass('d-none');
      dateValuesMessage.removeClass('d-none');
    }
    else {
      selectValuesMessage.addClass('d-none');
      checkboxValuesMessage.addClass('d-none');
      dateValuesMessage.addClass('d-none');
    }
  }

  function validateName() {
    // Check if nameInput exists and retrieve its value
    const name = nameInput.length ? nameInput.val().trim() : '';
    const hasSpaces = name.includes(' ') || /[A-Z]/.test(name);
    if (hasSpaces) {
      nameValuesMessage.removeClass('d-none');
    } else {
      nameValuesMessage.addClass('d-none');
    }
  }
  // Initial validation on page load
  validateValues();
  validateName();

  // Add event listeners for change and keyup to validate the input in real-time
  fieldTypeSelect.on('change', validateValues);
  valuesInput.on('keyup', validateValues);
  nameInput.on('keyup', validateName);
}

export { validateAttributeFields };
