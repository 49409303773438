$(document).on('turbolinks:load',function(){
  $('#document-select').select2({
  }).on("select2:select", function (evt) {
    var id = evt.params.data.id;
    console.log(id);
    var element = $(this).children("option[value="+id+"]");

    moveElementToEndOfParent(element);

    $(this).trigger("change");
  });
  var ele=$("#document-select").parent().find("ul.select2-selection__rendered");
  ele.sortable({
    containment: 'parent',
    update: function() {
      orderSortedValues();
      console.log(""+$("#document-select").val())
    }
  });
});
window.orderSortedValues = function() {
  var value = ''
  $("#document-select").parent().find("ul.select2-selection__rendered").children("li[title]").each(function(i, obj){

    var element = $("#document-select").children('option').filter(function () { return $(this).html() == obj.title });
    moveElementToEndOfParent(element)
  });
};

window.moveElementToEndOfParent = function(element) {
  var parent = element.parent();

  element.detach();

  parent.append(element);
};

