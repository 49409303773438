$(document).on('turbolinks:load',function(){
  $('#fax-number-select2').select2({
    tags:true,
    createTag: function (params) {
      var term = $.trim(params.term);
      if (term === '') {
        return null;
      }

      term = term.replace(/[^0-9]/gi, '');
      var new_text = term.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      if (term.length < 10 || term.length > 12 ){
        return null;
      }
      return {
        id: term,
        text: new_text,
        newTag: true // add additional parameters
      }
    },
    insertTag: function (data, tag) {
      var dont_add = false;
      dont_add = data.some(function(e){if (e["id"].includes(tag["id"])) {return true;}});
      if (tag["id"].length != 10){
        dont_add = true;
      }
      if (!dont_add){
        data.push(tag);
      }
    }
  });
})