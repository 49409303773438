// Define the functions in the global scope
$(document).on('turbolinks:load ajax:send ajax:success ajax:error ajax:complete ready', function () {
function display_alert(message, hasErrors) {
  if (!message) {
    return;
  }

    let alertElement = $('.alert').first();
    bind_alert_element_to_body();
    
    if (alertElement.length) {
      const alertClass = hasErrors ? 'alert-danger' : 'alert-success';

      alertElement.hide()
        .removeClass('alert-success alert-danger')
        .addClass(alertClass);

      alertElement.text(message).show();
      //alertElement[0].scrollIntoView({ behavior: 'smooth', block: 'start' });

      setTimeout(() => {
        alertElement.hide()
          .removeClass('alert-danger alert-success')
          .addClass('alert-success');
      }, 3000);
    }
  }

  function bind_alert_element_to_body() {
    let alertElement = $('.alert').first();
    if (!alertElement.length) {
      // If no alert element exists, create one dynamically with the specified structure
      alertElement = $(`
        <div class="alert alert-success alert-dismissible fade show no-display" role="alert">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      `);
      // Append the alert element to the top of the body
      $('body').prepend(alertElement);
    }
    return alertElement;
  }

// Expose the functions to the global scope
window.display_alert = display_alert;
window.bind_alert_element_to_body = bind_alert_element_to_body;

  // Now you can call display_alert function as needed
  // Example usage:
  // display_alert('This is a success message', false);
  // display_alert('This is an error message', true);
});
