// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require('jquery');
import $ from 'jquery';
import 'bootstrap';
// import 'bootstrap4-toggle';
import 'select2';
import '@fortawesome/fontawesome-free/js/all';
import Inputmask from 'inputmask';
import flatpickr from 'flatpickr';
import { validateAttributeFields } from './validation';
// import "../channels/cases_channel.js";
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
// require('channels');
require('./add_remove_fields');
require('./epa_form');
require('./case');
require('./disable_on_permission');
require('chartkick').use(require('highcharts'));
require('@client-side-validations/client-side-validations');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.jQuery = $;
window.$ = $;
require('jquery-ui-dist/jquery-ui.js');
import './select2_sortable';
import './select2_fax_number';

require('./precert_form.js');
require('./medical_benefit');
require('./prior_authorization');
require('./task');
require('./alert');

let popped = false;

function datepickerAndInput() {
    Inputmask().mask(document.querySelectorAll('input'));
    $("input[data-behavior='flatpickr']").flatpickr({
        altInput: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d',
        allowInput: true,
    });
    $("input[data-behavior='flatrangepickr']").flatpickr({
        mode: 'range',
        altInput: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d',
        showMonths: 2,
    });

    $.fn.select2.defaults.set('theme', 'bootstrap');
    $.fn.select2.defaults.set('dropdown-auto-width', 'true');
    $.fn.select2.defaults.set('width', '100%');

    $('.selectpicker').select2({
        theme: 'bootstrap',
        width: '100%'
    });
    $('.select-picker-ajax').select2({
        theme: 'bootstrap',
        minimumInputLength: 1,
        ajax: {
            delay: 250,
            quietMillis: 150,
            url: `/ref_icds`,
            dataType: 'json',
            data: function(params) {
                return {
                    code_search: params.term,
                };
            },
            processResults: function(data) {
                return {
                    results: refIcdData(data),
                };
            },
            cache: false,
        },
        allowClear: true,
        templateResult: icdOptionTemplate,
        templateSelection: icdSelectionTemplate,
        placeholder: 'Select Dx Codes'
    });

    const claimsButton = document.getElementById('claims_btn');
    // Get the Id of a loading spinner modal
    const modal = document.getElementById('myModal');

    const displayModal = (e) => {
        //e.preventDefault();
        modal.style.display = 'block';
    };

    // Listen the submit click on the form and call display spinner
    if (claimsButton != null) {
        claimsButton.addEventListener('click', displayModal);
    }
}

$(document).ready(function() {
    datepickerAndInput();
});

Object.keys(window).forEach((key) => {
    if (/^on/.test(key)) {
        window.addEventListener(key.slice(2), (event) => {
            if (event.type === 'popstate') {
                popped = true;
            } else {
                popped = false;
            }
        });
    }
});

document.addEventListener('turbolinks:load', function(event) {
    if (popped) {
        $(".input").remove();
        $('.selectpicker').select2('destroy');
        $('.select-picker-ajax').select2('destroy');
        $('.select2').remove();
        datepickerAndInput();
        $(".select2-search__field").css("width", "200px");
    } else {
        datepickerAndInput();
    }
});

window.select2drop = function(klass, model) {
    const baseSelector = `#${klass}_${model}_id`;
    const practiceId = `#${klass}_practice_id`;
    const globalDoctorId = `#${klass}_global_doctor_id`;
    const caseId = `#${klass}_case_id`;

    $(baseSelector).select2({
        ajax: {
            url: `/${model}s/`,
            dataType: 'json',
            delay: 250, // Delay in milliseconds
            data: function(params) {
                return {
                    search: params.term,
                    practice_id: $(practiceId).val(),
                    global_doctor_id: $(globalDoctorId).val(),
                    case_id: $(caseId).val(),
                };
            },
            processResults: function(data) {
                return { results: data };
            },
            cache: false,
        },
        minimumInputLength: 3, // Minimum characters required to trigger search
        allowClear: true,
        placeholder: `Select ${model.replace(/_/g, ' ')}...`,
        templateResult: optionTemplate,
        templateSelection: selectionTemplate,
    });
};


// Handle key events for number input fields only
$(document).on('keypress keyup blur', '.numbers-only', function(event) {
    $(this).val($(this).val().replace(/[^\d].+/, ""));
    if ((event.which < 48 || event.which > 57)) {
        event.preventDefault();
    }
});

// $(document).ready(function() {
//     $('.form-group').on('click', 'label', function(e) {
//         e.preventDefault();
//         return false;
//     });
// });

function optionTemplate(data) {
    if ($(data.html).length === 0) {
        return data.text ? data.text : data.dropname;
    }

    return $(data.html);
}

function selectionTemplate(data) {
    if ($(data.html).length === 0) {
        return data.text || data.dropname;
    }

    return $(data.html);
}

function icdOptionTemplate(data) {
    if ($(data.html).length === 0) {
        return data.text ? data.text : data.dropname;
    }

    return $(data.html);
}

function icdSelectionTemplate(data) {
    if ($(data.html).length === 0) {
        return data.text || data.dropname;
    }

    return $(data.html);
}

function refIcdData(data) {
    if (data) {
        let result_data = data.map((obj) => {
            let rObj = {};
            rObj['id'] = obj.code;
            rObj['text'] = obj.dropname;
            return rObj;
        });

        return result_data;
    } else {
        return [];
    }
}
/// start
// this code block related to attribute fields

document.addEventListener('turbolinks:load', function(event) {
    validateAttributeFields();
    $('#modal-window').on('shown.bs.modal', function() {
        validateAttributeFields()
    });
});

$(document).ready(function() {
    $(document).on('click', '.remove_section', function(e) {
        e.preventDefault();
        var element = $(this).closest('div')
            .prev()
            .find('[type=checkbox]')
        var persisted_element = $(this).attr('id').split('_').pop()
        if (confirm('Are you sure you want to delete this?')) {
            if ($(this).closest('section').attr('id')) {
                if (persisted_element) {
                    element.val('false');
                    element.removeAttr('checked');
                    $(this).closest('section').hide();
                } else {
                    return false;
                }
            } else {
                $(this).closest('section').remove();
            }
        }
    });
});
/// end

document.addEventListener('turbolinks:load', function() {
    // Select all elements with dynamic IDs
    var dynamicIds = $('[id^="js-data-"][id$="-ajax"]');
    // Extract and console the sections of the IDs
    dynamicIds.each(function() {
        var model = this.id.match(/js-data-(.*?)-ajax/)[1]; // detect model
        select2ajax(model)
    });
});

window.select2ajax = function(model) {
    let element = $(`#js-data-${model}-ajax`)
        //these below tw0 variables only for handle merge practices form large query
    let practice_npi = ''
    let practice_id = ''
    if (model === 'npi_practice') {
        practice_npi = element.data('practice-npi')
        practice_id = element.data('practice-id')
    }
    element.select2({
        ajax: {
            url: '/data/fetch',
            dataType: 'json',

            data: function(params) {
                return {
                    term: params.term,
                    model: model,
                    practice_npi: practice_npi,
                    practice_id: practice_id
                };
            },
            processResults: function(data) {
                return { results: data };
            },
            cache: false
        },
    });
};
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

$(document).on('turbolinks:load', function () {
    $('.send-for-review-link').on('click', function () {
        $(this).text('Sending...').addClass('disabled');
    });
});

 document.addEventListener('turbolinks:load', function() {
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('page');
    if (page) {
      const paginationContainer = $('#pagination-container');
      if( paginationContainer.length > 0){
        paginationContainer.removeClass('hidden');
        paginationContainer.focus();
      }
    }
  });
