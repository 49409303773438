import $ from 'jquery';

$(document).on('click', 'form .add_fields', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));
    $('.selectpicker').select2();
    $('input').enableClientSideValidations()
    return event.preventDefault();
});

$(document).on('click', 'form .remove_fields', function(event) {
  if(confirm("Are you sure you want to delete this?")) {
        if ($(this).closest('fieldset').find($("input[id$='id']" )).val()){
            $(`#destroy_${$(this).closest('fieldset').find($("input[id$='nested_class_name']")).val()}_${$(this).closest('fieldset').find($("input[id$='id']" )).val()}`).val(true);
            $(this).closest('fieldset').find(':input[required]').attr('required',false);
           if($(this).closest('fieldset').parent('div').attr('id')){
            $(this).closest('fieldset').parent('div').addClass('d-none');
           }else{
            $(this).closest('fieldset').parent('div').remove();
           }
        }else {
            $(this).closest('fieldset').parent('div').remove();
        }
        return event.preventDefault();
    }
    else{
        return false;
    }
});
