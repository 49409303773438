handleFormSubmission = function(event) {
  let actionText = (event.target.innerText && event.target.innerText.toLowerCase()) || 'proceed'

  let confirmationMessage = `Are you sure you would like to ${actionText}? This action can not be undone.`

  let confirmed = event.target.value === 'save' || confirm(confirmationMessage)

  if(confirmed) {
    //toggleSubmissionButtonSpinners()
    return true
  } else {
    event.preventDefault()
    return false
  }
}

handleInputSelect = function(target) {
  var precertFormsFromServer = JSON.parse(global_precert_form)


  var formId = $(target).closest('form').find('input[name*="form_id"]').val()

  var precertFormFromServer = precertFormsFromServer.find(function(form) {
    return form.id == formId
  }) || {}

  var precertFormFieldsFromServer = precertFormFromServer.fields || []

  var fieldName = target.name.split('precert[').join('').split(']').join('')

  var targetField = precertFormFieldsFromServer.find(function(field) {
    return field.name === fieldName
  })

  if (!targetField) { return }

  var children = precertFormFieldsFromServer.reduce(function(acc, curr) {
    if(curr.parent_id === targetField.id) {
      acc.push(curr)
    }

    return acc
  }, [])

  var targetValue = extractTargetValue(target)
  var targetAnswers = extractTargetAnswers(targetField)

  children.forEach(function(child) {
    var childRow = document.getElementById(`${child.name}_row`)
    !childRow && (childRow = document.getElementById(`label_${child.id}_row`))
    var childElement = $(childRow).find(`:input[name="precert[${child.name}]"]`)
    !childElement.length && (childElement = $(childRow).find(`label[parent_id="${targetField.id}"]`))

    var renderCondition = child.render_condition.toLowerCase()

    if (childRow && renderCondition === 'false' && shouldRenderOnFalse(targetValue)) {
      childRow.hidden = false
      $(childElement).removeAttr('disabled')
    } else if (childRow && renderCondition === 'true' && shouldRenderOnTrue(targetValue, child.answers)) {
      childRow.hidden = false
      $(childElement).removeAttr('disabled')
    } else if (childRow && renderCondition && shouldRenderFromAnswers({value: targetValue, answers: targetAnswers, condition: renderCondition})) {
      childRow.hidden = false
      $(childElement).removeAttr('disabled')
    } else if (childRow) {
      childRow.hidden = true
      $(childElement).attr('disabled', 'disabled')
    }
  });
}

function shouldRenderOnFalse(value) {
  return value && (value === '' || value == false || value === 'no')
}

function shouldRenderOnTrue(value, child_value) {
  var lower_case_value = makeStringLowerCase(child_value)
  return value && (value !== '' && value != false && value !== 'no') && lower_case_value.includes(value)
}

function shouldRenderFromAnswers(props={}) {
  var value = props.value
  var answers = props.answers
  var condition = props.condition
  var answerIndex = parseInt(condition) - 1

  if (!Number.isNaN(answerIndex) && answers.indexOf(value) == answerIndex) {
    return true
  } else {
    return false
  }
}

function extractTargetValue(target) {
  if(target.type === 'checkbox') {
    return target.checked
  } else if (target.value && typeof target.value === 'string') {
    return target.value.toLowerCase()
  } else {
    return target.value
  }
}

function extractTargetAnswers(target={}) {
  var answers = target.answers || ''

  if(answers && Array.isArray(answers)) {
    return answers.map( i => `${i}`.toLowerCase() )
  } else {
    return answers
  }
}

// function toggleSubmissionButtonSpinners(hideButtons = true) {
//     var showSpinner = !hideButtons

//     var formButtons = document.getElementsByClassName('precert-form-buttons')
//     var loadingSpinners = document.getElementsByClassName('precert-form-loading-container')

//     $(formButtons).each(function(index) {
//         formButtons[index].hidden = hideButtons
//     })

//     $(loadingSpinners).each(function(index) {
//         loadingSpinners[index].hidden = showSpinner
//     })

// }

function updatePrecertFormSelection(element) {
  var formId = element.options[element.selectedIndex].value

  $('.precert_form_field_option').each(function() {
    $(this).addClass('d-none')
  })

  var saveAndCompleteButton = $('#save_and_complete_button')

  if(formId) {
    $(`.precert_form_id-${formId}`).removeClass('d-none')
    $(saveAndCompleteButton).prop('disabled', false)
    $(saveAndCompleteButton).prop('title', 'You can now submit this form to the insurance company!')
  } else {
    $(saveAndCompleteButton).prop('disabled', 'disabled')
    $(saveAndCompleteButton).prop('title', 'A form must be selected (in the above drop-down) to be submitted to the insurance company.')
  }
}

$('#precert-form')
  .on('ajax:error', function(event) {
    //toggleSubmissionButtonSpinners(false)

    toastr['error']('Internal server error. Please contact IT.')
  })

$(document).ready(function() {
  var precertFormSelect = $('select[name*="[form_id]"]')[0]

  if(precertFormSelect) {
    updatePrecertFormSelection(precertFormSelect)
  }
})

function makeStringLowerCase(data_array){
  data_array.forEach((ele, index, data_array) => {
    if (typeof ele === 'string') {
      data_array[index] = ele.toLowerCase()
    }
  })
  return data_array
}